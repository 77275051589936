<template>
  <div class="row">
    <div class="col-lg-3">
      <AsideFilter @changed="onAsideFilterChanged" />
    </div>
    <div class="col-lg-9">
      <div class="card card-custom card-stretch">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
          <div class="card-title">
            <h3 class="card-label">Operasyonlar</h3>
          </div>
        </div>

        <div class="card-body">
          <div class="mb-10">
            <div class="row align-items-center">
              <div class="col-md-6 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Ara..."
                    v-model="searchQuery"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <AppliedFilters @changed="onAppliedFiltersChanged" />
          </div>
          <div>
            <ListingTable
              ref="table"
              :filter="searchQuery"
              @total-rows="setTotalRows"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsideFilter from "@/view/pages/operation/components/listings/AsideFilter";
import AppliedFilters from "@/view/pages/operation/components/listings/AppliedFilters";
import ListingTable from "@/view/pages/operation/components/listings/ListingTable";

export default {
  components: {
    AsideFilter,
    AppliedFilters,
    ListingTable
  },
  data() {
    return {
      searchQuery: "",
      totalRows: 0
    };
  },
  methods: {
    onAsideFilterChanged() {
      this.$refs["table"].get();
    },
    onAppliedFiltersChanged() {
      this.asideFilterKey += 1;
      this.$refs["table"].get();
    },
    setTotalRows(rows) {
      this.totalRows = rows;
    }
  }
};
</script>

<style></style>
