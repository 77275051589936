<template>
  <div>
    <b-table
      class="table table-head-custom table-vertical-center"
      thead-class="mb-8"
      show-empty
      hover
      empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty.svg" style="width:30%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir rezervasyon bulunamadı.</label> 
          </div>'
      stacked="lg"
      :items="operations"
      :fields="operationsDataTableFields"
      :current-page="currentPage"
      :per-page="perPage"
      :busy="isBusy"
      :filter="filter"
      @filtered="onFiltered"
      @row-clicked="onRowClicked"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-5"></b-spinner>
          <strong>Yükleniyor...</strong>
        </div>
      </template>
      <template #cell(product)="data">
        <div class="py-2 pl-0 max-w-350px">
          <div class="d-flex align-items-start">
            <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${data.item.product.image})`
                }"
              ></div>
            </div>
            <div>
              <span
                class="
                    font-weight-bolder
                    d-block
                    font-size-md
                  "
                :class="[
                  { 'text-warning': data.item.product.serviceType === 1 },
                  { 'text-info': data.item.product.serviceType === 2 }
                ]"
              >
                <template v-if="data.item.product.serviceType === 1"
                  >Grup</template
                >
                <template v-if="data.item.product.serviceType === 2"
                  >Özel</template
                >
                <span class="text-muted font-weight-bold ml-2"
                  >#{{ data.item.pnr }}</span
                >
              </span>
              <span
                class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    mb-2
                    font-size-lg
                  "
                >{{ data.item.product.name }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <template #cell(driver)="data">
        <span
          class="
              text-dark-75
              font-weight-bold
              d-block
              font-size-lg
            "
          >{{ data.item.transportation.company }}</span
        >
        <span
          class="
              text-muted
              font-weight-bold
              d-block
              font-size-lg
            "
          >{{ data.item.transportation.driver }}</span
        >
      </template>
      <template #cell(dateTime)="data">
        <span class="text-dark-75 font-weight-bold d-block font-size-lg">
          {{
            renderDateTime(data.item.departureDateTime, data.item.endDateTime)
          }}
        </span>
      </template>
      <template #cell(pax)="data">
        <span class="text-dark-75 font-weight-bold d-block font-size-lg">
          {{ data.item.pax }} Pax
        </span>
      </template>
      <template #cell(profit)="data">
        <span
          class="font-weight-bolder d-block font-size-lg"
          :class="[
            { 'text-danger': data.item.profit.value < 0 },
            { 'text-success': data.item.profit.value > 0 }
          ]"
          >{{ data.item.profit.text }}</span
        >
      </template>
      <template #cell(action)="data">
        <button
          class="btn btn-icon btn-light btn-hover-danger btn-sm mr-2"
          @click="data.toggleDetails"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="media/svg/icons/Navigation/Angle-down.svg" />
          </span>
        </button>
      </template>
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="2" class="font-weight-bolder text-muted text-sm-right"
              >Araç:</b-col
            >
            <b-col class="font-weight-bolder">{{
              row.item.transportation.vehicle
            }}</b-col>
            <b-col sm="2" class="font-weight-bolder text-muted text-sm-right"
              >Şoför:</b-col
            >
            <b-col class="font-weight-bolder">{{
              row.item.transportation.driver
            }}</b-col>
            <b-col sm="2" class="font-weight-bolder text-muted text-sm-right"
              >Pax:</b-col
            >
            <b-col class="font-weight-bolder">{{ row.item.pax }} Pax</b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        class="my-0"
        size="md"
      >
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ApiService from "@/core/services/api.service";

export default {
  props: ["filter"],
  computed: {
    ...mapGetters(["startDate", "endDate", "themes", "serviceTypes"])
  },
  data() {
    return {
      operations: [],
      operationsDataTableFields: [
        { key: "product", label: "Ürün", sortable: false },
        { key: "driver", label: "ARAÇ", sortable: false },
        { key: "dateTime", label: "HAREKET TARİHİ", sortable: false },
        { key: "pax", label: "PAX", sortable: false },
        {
          key: "profit",
          label: "NET KÂR",
          sortable: false,
          class: "text-right"
        },
        { key: "action", label: "", sortable: false, class: "text-right" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 30,
      filterOn: [],
      isBusy: false
    };
  },
  mounted() {
    moment.locale("tr");
    this.get();
  },
  methods: {
    get() {
      this.isBusy = true;

      if (this.themes.length) {
        var tParam = "t=" + this.themes.join();
      }
      if (this.serviceTypes.length) {
        var stParam = "st=" + this.serviceTypes.join();
      }
      if (this.startDate && this.endDate) {
        var startParam = "start=" + this.startDate;
        var endParam = "end=" + this.endDate;
      }

      ApiService.get(
        `operation/listings.req.php?${startParam}&${endParam}&${tParam}&${stParam}`
      )
        .then(({ data }) => {
          this.operations = data.operations;
          this.totalRows = this.operations.length;
          setTimeout(() => {
            this.isBusy = false;
            this.$emit("total-rows", this.totalRows);
          }, 500);
        })
        .catch(() => {
          this.operations = [];
          this.totalRows = 0;
          setTimeout(() => {
            this.isBusy = false;
          }, 500);
        });
    },
    renderDateTime(departureDate, endDate) {
      let departure = moment(departureDate, "YYYY-MM-DD HH:mm:ss");
      let end = moment(endDate, "YYYY-MM-DD HH:mm:ss");

      if (
        moment(departure.format("YYYY-MM-DD")).isSame(end.format("YYYY-MM-DD"))
      ) {
        return departure.format("D MMM YYYY, HH:mm");
      } else {
        if (moment(departure.format("YYYY-MM")).isSame(end.format("YYYY-MM"))) {
          return departure.format("D-") + end.format("D MMM YYYY");
        } else {
          return departure.format("D MMM - ") + end.format("D MMM");
        }
      }
    },
    openStorage() {
      return JSON.parse(sessionStorage.getItem("operation-listings-filter"));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(e) {
      this.$router.push({
        name: "operation-details",
        params: { pnr: e.pnr }
      });
    }
  }
};
</script>

<style>
.table-hover tbody tr:hover > td {
  cursor: pointer;
}
</style>
