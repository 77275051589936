<template>
  <div class="d-flex flex-row flex-wrap align-items-center mb-5">
    <Chip
      v-if="hasDate"
      title="Tarih"
      :value="formattedDateRange"
      @close="removeDateRange"
    />
    <Chip
      v-for="theme in themes"
      :key="theme"
      title="Tema"
      :value="experienceConfig(`theme`)[theme].tr"
      @close="removeTheme(theme)"
    />
    <Chip
      v-for="serviceType in serviceTypes"
      :key="serviceType"
      title="Hizmet Tipi"
      :value="experienceConfig(`serviceType`)[serviceType].tr"
      @close="removeServiceType(serviceType)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  UPDATE_THEMES,
  UPDATE_SERVICE_TYPES
} from "@/core/services/store/filter-operations.module";
import Chip from "@/view/library/Chip";

export default {
  name: "AppliedFilters",
  components: {
    Chip
  },
  computed: {
    ...mapGetters([
      "experienceConfig",
      "startDate",
      "endDate",
      "formattedDateRange",
      "themes",
      "serviceTypes"
    ]),

    hasDate() {
      return this.startDate !== "" || this.endDate !== "";
    }
  },
  methods: {
    removeDateRange() {
      this.$store.dispatch(UPDATE_START_DATE, "");
      this.$store.dispatch(UPDATE_END_DATE, "");
      this.$emit("changed");
    },
    removeTheme(theme) {
      this.$store.dispatch(
        UPDATE_THEMES,
        this.themes.filter(el => el !== theme)
      );
      this.$emit("changed");
    },
    removeServiceType(serviceType) {
      this.$store.dispatch(
        UPDATE_SERVICE_TYPES,
        this.themes.filter(el => el !== serviceType)
      );
      this.$emit("changed");
    }
  }
};
</script>
