<template>
  <vc-date-picker
    v-if="isInline"
    class="inline-block h-full"
    is-range
    :title-position="titlePosition"
    :is-dark="isDark"
    :model-config="modelConfig"
    :update-on-input="false"
    :input-debounce="500"
    :masks="{ input: ['DD MMM YYYY'] }"
    v-model="dateRange"
    color="blue"
    locale="tr"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div class="input-icon">
        <input
          type="text"
          placeholder="Tarih aralığı seçin"
          class="form-control form-control-solid font-weight-bold"
          readonly
          :value="
            inputValue.start ? `${inputValue.start} - ${inputValue.end}` : ''
          "
          @click="togglePopover"
        />
        <span>
          <i class="flaticon2-calendar-9 text-muted"></i>
        </span>
      </div>
    </template>
  </vc-date-picker>
  <vc-date-picker
    v-else
    color="red"
    locale="tr"
    is-range
    :title-position="titlePosition"
    :is-dark="isDark"
    :model-config="modelConfig"
    v-model="dateRange"
  />
</template>

<script>
export default {
  name: "DateRangePicker",
  props: {
    initialDateRange: {
      type: Object
    },
    isInline: {
      type: Boolean,
      default: false
    },
    isDark: {
      type: Boolean,
      default: false
    },
    titlePosition: {
      type: String,
      default: "center",
      validator: value => {
        return ["left", "center", "right"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD" // Uses 'iso' if missing
      },
      dateRange: ""
    };
  },
  mounted() {
    if (this.initialDateRange) {
      this.dateRange = this.initialDateRange;
    }
  },
  watch: {
    dateRange() {
      this.$emit("changed", this.dateRange);
    }
  }
};
</script>

<style scoped>
.form-control {
  cursor: pointer;
}
</style>
