<template>
  <label
    class="checkbox checkbox-pill font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light"
    :class="checkboxTheme"
  >
    <input
      type="checkbox"
      :value="value"
      @change="onChange($event)"
      :checked="isChecked"
    />
    <span class="mr-3"></span>
    {{ text }}
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    text: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: "dark",
      validator: value => {
        return ["dark", "success", "warning", "danger"].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    checkboxTheme() {
      return `checkbox-${this.theme}`;
    }
  },
  methods: {
    onChange(event) {
      this.$emit("changed", event.target.checked);
    }
  }
};
</script>
