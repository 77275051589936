<template>
  <div class="card card-custom">
    <!-- BEGIN: Header -->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Filtrele
          <span class="d-block text-muted pt-2 font-size-sm"></span>
        </h3>
      </div>
    </div>
    <!--END: Header -->
    <!-- BEGIN: Body -->
    <div class="card-body">
      <!-- BEGIN: Date Range Picker -->
      <div class="mb-12">
        <DateRangePicker
          :key="dateRange"
          is-inline
          :initial-date-range="dateRange"
          @changed="onDateRangeChanged"
        />
      </div>
      <!-- END: Date Range Picker -->
      <!-- BEGIN: Theme -->
      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Tema</div>
        <div class="checkbox-list">
          <Checkbox
            v-for="item in themeList"
            :key="`theme__${item.key}`"
            :text="item.value"
            :value="item.key"
            :is-checked="themes.includes(item.key)"
            @changed="onThemeCheckboxChange($event, item.key)"
          />
        </div>
      </div>
      <!-- END: Theme -->
      <!-- BEGIN: Service Types -->
      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">
          Hizmet Tipi
        </div>
        <div class="checkbox-list">
          <Checkbox
            v-for="item in serviceTypeList"
            :key="`service-type__${item.key}`"
            :text="item.value"
            :value="item.key"
            :is-checked="serviceTypeList.includes(item.key)"
            @changed="onServiceTypeCheckboxChange($event, item.key)"
          />
        </div>
      </div>
      <!-- END: Service Types -->
    </div>
    <!-- END: Body -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  UPDATE_THEMES,
  UPDATE_SERVICE_TYPES
} from "@/core/services/store/filter-operations.module";
import DateRangePicker from "@/view/library/DateRangePicker";
import Checkbox from "@/view/library/Checkbox";
export default {
  name: "OperationsAsideFilter",
  components: {
    DateRangePicker,
    Checkbox
  },
  computed: {
    ...mapGetters([
      "experienceConfig",
      "startDate",
      "endDate",
      "themes",
      "serviceTypes"
    ]),
    dateRange() {
      return {
        start: this.startDate,
        end: this.endDate
      };
    },
    themeList() {
      return Object.entries(this.experienceConfig(`theme`)).map(
        ([key, value]) => {
          return {
            key,
            value: value.tr
          };
        }
      );
    },
    serviceTypeList() {
      return Object.entries(this.experienceConfig(`serviceType`)).map(
        ([key, value]) => {
          return {
            key,
            value: value.tr
          };
        }
      );
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  methods: {
    onDateRangeChanged(dateRange) {
      this.$store.dispatch(UPDATE_START_DATE, dateRange.start);
      this.$store.dispatch(UPDATE_END_DATE, dateRange.end);
      this.triggerChangedEvent();
    },
    onThemeCheckboxChange(isChecked, key) {
      if (isChecked) {
        this.$store.dispatch(UPDATE_THEMES, [...this.themes, key]);
      } else {
        this.$store.dispatch(
          UPDATE_THEMES,
          this.themes.filter(el => el !== key)
        );
      }
      this.triggerChangedEvent();
    },
    onServiceTypeCheckboxChange(isChecked, key) {
      if (isChecked) {
        this.$store.dispatch(UPDATE_SERVICE_TYPES, [...this.serviceTypes, key]);
      } else {
        this.$store.dispatch(
          UPDATE_SERVICE_TYPES,
          this.serviceTypes.filter(el => el !== key)
        );
      }
      this.triggerChangedEvent();
    },
    triggerChangedEvent() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("changed");
      }, 750);
    }
  }
};
</script>
